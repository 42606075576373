import React from 'react';
import shape from '../../images/ct-shape.png';

const ContactInfo = ({ globalDetails }) => {
  return (
    <section className='contact-info-section section-padding' style={{ background: 'lightblue' }}>
      <div className='container'>
        <div className='office-info'>
          <div className='row'>
            <div className='col col-xl-4 col-lg-6 col-md-6 col-12'>
              <div className='office-info-item' style={{ height: '320px' }}>
                <div className='office-info-icon'>
                  <div className='icon'>
                    <i className='fi flaticon-place'></i>
                  </div>
                </div>
                <div className='office-info-text'>
                  <p>{globalDetails?.addressLine1}.</p>
                  <span>{globalDetails?.addressLine2}</span>
                </div>
              </div>
            </div>
            <div className='col col-xl-4 col-lg-6 col-md-6 col-12'>
              <div className='office-info-item active'>
                <div className='office-info-icon'>
                  <div className='icon'>
                    <i className='fi flaticon-phone-call'></i>
                  </div>
                </div>
                <div className='office-info-text'>
                  <p>{globalDetails?.mobile}</p>
                  <br></br>
                  <span>call us today</span>
                </div>
              </div>
            </div>
            <div className='col col-xl-4 col-lg-6 col-md-6 col-12'>
              <div className='office-info-item'>
                <div className='office-info-icon'>
                  <div className='icon'>
                    <i className='fi flaticon-email'></i>
                  </div>
                </div>
                <div className='office-info-text'>
                  <p>{globalDetails?.email}</p>
                  <br></br>
                  <span>EMAIL ADDRESS</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='ct-shape'>
        <img src={shape} alt='shape' />
      </div>
    </section>
  );
};

export default ContactInfo;
