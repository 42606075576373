import React, {Fragment, useContext, useRef} from 'react';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer from '../../components/footer/Footer';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar from '../../components/Navbar/Navbar'
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.jpg'
import Contactpage from '../../components/Contactpage/Contactpage';
import UserContext from '../../components/ContextApi/UserContext';
import GlobalContext from '../../components/ContextApi/GlobalContext';
import CardBanner from '../../components/CardBanner/CardBanner';
import HomePageSlider from '../../components/HomePageSlider/HomePageSlider';

const HomePage =() => {
    const section1Ref = useRef(null);
    const { user } = useContext(UserContext)
    const { globalDetails } = useContext(GlobalContext)

    const scrollToSection = () => {
        section1Ref.current.scrollIntoView({ behavior: 'smooth' });
      };
    

    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} scrollToSection={scrollToSection}/>
            <Hero/>
            <HomePageSlider/>
            <CardBanner/>
            <div ref={section1Ref} className='register_section'>
            {/* <Contactpage user={user}/> */}
            </div>
            <About abimg={abimg}/>
            <ServiceSection/>
            <CtaSection scrollToSection={scrollToSection}/>
            <FunFact/>
            <TeamSection/>
            <ProjectSection/>
            <BlogSection/>
            <PartnerSection/>
            <ContactInfo globalDetails={globalDetails}/>
        
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;