import Contactpage from "../../components/Contactpage/Contactpage"
import Navbar from "../../components/Navbar/Navbar"





const ApplyGuest = () =>{
    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <Contactpage/>
        
        </>
    )
}


export default ApplyGuest